import React from "react";

export default class Service extends React.Component{
    render(){

        return (
            <div className={'serviceBlock'}>
                <h3>{this.props.name}</h3>
                <hr/>
                <p>{this.createParagraphs(this.props.description)}</p>
                {this.createListItems(this.props.list)}
            </div>
        );
    }

    createParagraphs(text){
        const sections = text.split("</br>");
        return sections.map(item => {
            return <p>{item}</p>
        });
    }

    createListItems(items){
        if(items && items.length){
            const reactItems = items.map(item => {
                return <li>{item}</li>
            });
            return <ul>
                {reactItems}
            </ul>
        }
        return null;
    }
}