import React from "react";
import Base from "../components/Base";
import Service from "../components/Service";

const services = () => {
    return (
            <div>
                <Base title={'Services'} description={'View the commonly offered services that we provide for both our commercial and domestic customers. We are operating out of Harrow and surrounding areas, with over 10 years of experience specializing in domestic and commercial installations.'}>                
                    <h2 className={'colouredTitle'}>Services</h2>
                    <p>Below is a list of commonly offered services that we provide. However even if your request doesn't fall under the services listed below still don't hesitate to contact us!</p>                
                    <Service
                        name={'Domestic Electricial Work'}
                        description={'We provide a broad range of domestic electrical services listed below:'}
                        list={[
                            "FREE visual inspection",
                            "Full & partial rewiring",
                            "Lighting",
                            "Security & flood lighting",
                            "Home security systems",
                            "Fault finding",
                            "Electrical certificates",
                            "Fuseboard installation and upgrades",
                            "Kitchen refurbishments",
                            "Maintenance"
                        ]}
                    />
                    <Service
                        name={'Smart Home Systems'}
                        description={`Control your home from your smartphone and tablet allowing you to control your lights, thermostat and security systems. We support installation of all major brands including Philips Hue, Google Nest, Lutron and Ring.`}
                    />
                    <Service
                        name={'Landlords'}
                        description={`As a landlord it is important that your property is well maintained and meets the current UK standards for your tenants.
                        All estate agents require evidence of electrical testing so they can rent your property to any member of the public. The evidence you provide for this is called an electrical installation condition report (EICR). These reports are the best way to get an idea of how safe your electrics are and if improvements are needed.</br>
                        We provide the following services to Landlords, Estate and Letting Agents:
                        `}
                        list={[
                            "Electrical Installation Condition Reports (EICR)",
                            "Fire Alarms",
                            "Emergency Callouts",
                            "Planned Maintenance",
                            "Emergency Lights"
                        ]}
                    />
                    <Service
                        name={'Commerical Electricial Work'}
                        description={`We understand that your business is important to you this is why we provide a 24-hour service, so that there is no disruption to your business.
                        We are happy to work in the evening or through the night to ensure your business can operate normally the next day.</br>
                        Commerical services we offer:
                        `}                        
                        list={[
                            "FREE visual inspection",
                            "Fuseboard installation and upgrades",
                            "Full and part rewires",
                            "Emergency lighting",
                            "Data and telephone points",
                            "Fault finding",
                            "Maintenance",
                            "Security systems",
                            "New office and shop layouts"
                        ]}
                    />
                    <Service
                        name={'Electrical Testing & Certification'}
                        description={``}
                        list={[
                            "Domestic and commerical electrical testing",
                            "Electrical testing and period report (EICR)",
                            "Visual inspection and electrical testing",
                            "PAT Testing",
                            "Electrical testing for landlords",
                            "Fault finding"
                        ]}
                    />
                </Base>
            </div>
        );
}
export default services;